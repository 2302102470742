








































































































import {Vue, Component} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import Workspaces from "@/state/Workspaces";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import CompanyService from "@/services/CompanyService";
import BankAccountDTO from "@/dto/BankAccountDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PersonService from "@/services/PersonService";
import CreationBankAccountModal from "@/components/profile/bank/BankAccountCreationModal.vue";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import RouteNames from "@/router/RouteNames";

const AppModule = namespace("App");
const Auth = namespace("Auth");


@Component({})
export default class BankAccountList extends Vue {

  private bankAccounts: Array<BankAccountDTO> = [];

  private RouteNames = RouteNames;

  private message = "";

  private successful = true;

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  mounted() {
    this.loadPage()
  }

  loadPage() {
    switch (Workspaces.getCurrentType) {
      case WorkspaceType.COMPANY:
        this.fetchCompanyBankAccounts();
        break
      case WorkspaceType.PERSON:
        this.fetchIndividualBankAccounts();
        break
    }
  }

  private fetchCompanyBankAccounts() {
    this.startLoading();
    CompanyService.getBankAccounts(Workspaces.getCurrent.id).then(
        res => {
          this.bankAccounts = res.data;
          this.stopLoading();
        },
        err => this.processError(err)
    )
  }

  private processError(err: any) {
    this.successful = false;
    this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
    this.stopLoading();
  }

  private fetchIndividualBankAccounts() {
    this.startLoading();
    PersonService.getBankAccounts(Workspaces.getCurrent.id).then(
        res => {
          console.log(res.data)
          this.bankAccounts = res.data;
          this.stopLoading();
        },
        err => this.processError(err)
    )
  }


  private addBankAccount() {
    this.$modal.show(
        CreationBankAccountModal,
        {
          onSuccess: () => this.loadPage()
        },
        {backgroundColor: '#ccc'}
    );
  }

  private download(doc: SignableDocumentDTO) {
    if (doc.signedDocument) {
      Vue.prototype.$docUtils.download(doc.signedDocument.id)
    } else {
      Vue.prototype.$docUtils.download(doc.primaryDocument!.id)
    }
  }
}
